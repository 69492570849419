import './jqueryLib'
import 'slick-carousel'

(function ($) {
    $(function () {
        if ($('.carousel').length) {
            $('.carousel').show();
            $('.carousel').slick({
                dots: false,
                centerMode: false,
                infinite: true,
                speed: 1000,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1800,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false,
                            centerMode: true,
                        }
                    },

                    {
                        breakpoint: 1500,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false,
                            centerMode: false,
                        }
                    },
                    {
                        breakpoint: 876,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: false,
                            centerMode: false,
                        }
                    },

                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: false,
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });


            $('.arrow-left').click(function () {
                $('.carousel').slick('slickPrev');
            })

            $('.arrow-right').click(function () {
                $('.carousel').slick('slickNext');
            })
        }
    })
})(jQuery);